import { types } from "../types/types";
import { htmlMsg, msg } from "./swal_msg";
import { protectedReq } from "../helpers/requestHelper";

// agrega método de envío
export const saveShippingMethod = (model) => {
    return async(dispatch) => {
        try {
            const { min_day, max_day, ...obj } = model;
            const method = { ...obj, delivery_days: [min_day, max_day] };
            const resp = await protectedReq('shipping/add-method', method, 'POST');
            const body = await resp.json();
            if (body.status)
                dispatch(shippingMethodSaved({
                    ...model,
                    _id: body.resp._id,
                    cost: Math.round((model.cost * 1.16) * 1e2) / 1e2,
                    checked: false
                }));
            else {
                if (body.msg === 'server-err')
                    msg('error', 'Métodos de envío',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    msg('warning', 'Métodos de envío',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            htmlMsg('error', 'Métodos de envío',
            `<p>No se pudo agregar el método de envío</p><h6>Error: ${err}</h6>`);
        }
    }
};

// método de envío guardado
export const shippingMethodSaved = (payload) => ({
    type: types.shippingMethodSaved,
    payload
});

// elimina método(s) de envío
export const deleteShippingMethod = (array) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('shipping/delete-many', { array }, 'DELETE');
            const body = await resp.json();
            if (body.status)
                dispatch(shippingMethodsDeleted(array));
            else {
                if (resp.msg === 'not-deleted') {
                    msg('warning', 'Métodos de envío',
                    'Uno o más métodos pudieron no ser eliminados');
                } else if (resp.msg === 'server-err') {
                    msg('warning', 'Métodos de envío',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                } else msg('warning', 'Métodos de envío',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            htmlMsg('error', 'Métodos de envío',
            `<p>No se pudo eliminar el método(s) de envío</p><h6>Error: ${err}</h6>`);
        }
    };
};

// método(s) de envío eliminado(s)
export const shippingMethodsDeleted = (payload) => ({
    type: types.shippingMethodsDeleted,
    payload
});