import React, { useEffect, useState, useRef, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

//Owl carousel
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

//Swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

import './ProductSample.css'

import { basicMsg, cartDialog } from '../../../actions/swal_msg';
import { commentModalToggle } from '../../../actions/ui';
import {
    navigateToProductPage,
    saveItemToCart,
    shopClearAction,
    shopProductViewed
} from '../../../actions/shop';

import { NavContext } from '../../../context/NavContext';

import { regex_num } from '../../../constants/regex';
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';
import { prodInit, varInit } from '../../../constants/shop/shop_constants';

import payments from '../../../assets/images/Payments.jpg'
// componentes
import ProductsFBT from './ProductsFBT';
import ReviewModal from './Review/ReviewModal';
import ProductReview from './Review/ProductReview';
import RecentlyViewed from '../RecentlyViewed/RecentlyViewed';

//gallery
import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';
import shape from '../../../assets/resources/shape.png';

//MUI
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import {
    Typography, Breadcrumbs, Backdrop, CircularProgress,
    Stack, Container, Grid, Box, TextField, Button,
    AccordionSummary, AccordionDetails, FormControl,
    useMediaQuery
    // FormGroup, FormControlLabel, Checkbox,
    // Chip, Tab, Tooltip, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import PhoneIcon from '@mui/icons-material/Phone';
import MuiAccordion from '@mui/material/Accordion';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReactImageMagnify from 'react-image-magnify';

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { TabContext, TabList, TabPanel } from '@mui/lab';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderTop: `1px solid #CACFD2`,
    borderBottom: `1px solid #CACFD2`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const WhatsAppButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    height: 40,
    color: '#fff',
    backgroundColor: '#25d366',
    '&:hover': {
        backgroundColor: '#52BE80',
        color: '#fff'
    },
}));

const PhoneButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    height: 40,
    color: '#fff',
    backgroundColor: '#C0392B',
    '&:hover': {
        backgroundColor: '#E74C3C',
        color: '#fff'
    },
}));

const openAdd = {
    open: true,
    action: 'add',
    loading: false
};

const tagManagerArgs = {
    gtmId: 'GTM-KQMW2JG'
};
TagManager.initialize(tagManagerArgs);

const ProductSample = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setValue } = useContext(NavContext);

    const matches = useMediaQuery('(max-width: 950px)');
    const matches_1 = useMediaQuery('(max-width: 410px)');
    const matches_2 = useMediaQuery('(max-width: 500px)');

    // ruta anterior
    const { state, pathname } = useLocation();
    // URL producto
    const { product: product_url } = useParams();

    // productos de tienda y datos de navegación
    const { action, categories, products, navigation, promotions } = useSelector(state => state.shop);
    const { nav_id } = navigation; // _id de variante a mostrar

    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { _id: account_id, username: logged_user } = loggedUser;

    // producto y variantes
    const [product, setProduct] = useState(prodInit);
    const {
        _id: prod_id, title, url, info,
        category: product_categories, refrigerated, medicine,
        prod_variants, reviews
    } = product;
    // promedio de reseñas
    const [avgReviews, setAvgReviews] = useState(0);

    // variante activa
    const [variant, setVariant] = useState(varInit);
    const {
        _id: prod_var_id, variant_title, price,
        stock, description, item_promotion
    } = variant;

    // imágenes de variante activa
    const [currentImages, setCurrentImages] = useState([]);
    // cantidad
    const [qty, setQty] = useState(1);
    // URL ruta anterior
    const [categoryUrl, setCategoryUrl] = useState('');

    // deshabilita realizar otra reseña
    const [enabled, setEnabled] = useState(true);
    // productos comprados juntos frecuentemente
    const [fbtItems, setFBTItems] = useState([]);

    // categoría seleccionada
    const [category, setCategory] = useState('');

    useEffect(() => {
        // obtenemos categoría mediante la ruta previa
        if (state) // state = { previousPath : "/tienda/category" }
            setCategoryUrl(state.previousPath.split('/').pop());
    }, []);

    useEffect(() => {
        const found = categories.find(e => e.url === categoryUrl);
        if (found)
            setCategory(found.category);
    }, [categoryUrl, categories]);

    // lista de productos actualizada / cambió url de producto
    useEffect(() => {
        if (products.length > 0) {
            const ProductModel = products.find(e => e.url === product_url);
            if (ProductModel) {
                const {
                    _id, title, url, prod_variants, reviews
                } = ProductModel;
                setProduct(ProductModel);

                if (prod_variants.length > 0) { // variantes disponibles
                    // elegir variante a mostrar
                    const VariantModel = nav_id !== ''
                        // se accedió desde carrito (se conoce la variante)
                        ? prod_variants.find(e => e._id === nav_id)
                        // se accedió desde tienda
                        : prod_variants[0];
                    const {
                        _id: prod_var_id, variant_title, price,
                        iva, stock, barcode, images, item_promotion
                    } = VariantModel;
                    // variante a mostrar
                    setVariant({ ...VariantModel, checked: true });

                    dispatch(shopProductViewed(account_id,
                        {
                            prod_id: _id, prod_var_id,
                            title, variant_title, url,
                            price: ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo)
                                ? item_promotion.price
                                : price,
                            iva, stock, images,
                            reviews, item_promotion
                        }
                    ));

                    if (images.length > 0)
                        setCurrentImages(images.map(e =>
                        ({
                            original: `${GOOGLE_DRIVE_CDN}${e.id}`,
                            thumbnail: `${GOOGLE_DRIVE_CDN}${e.id}`
                        })
                        ));
                    else
                        setCurrentImages([{
                            original: no_image,
                            thumbnail: no_image
                        }]);
                    // incluir código de barras en URL
                    navigate(`${pathname}?variant=${barcode}`, { replace: true });
                }
                // crear nuevo arreglo e incluir propiedad 'checked'
                if (ProductModel.fbt_item.length > 0)
                    setFBTItems(ProductModel.fbt_item);
            }
        }
        // quizá mover de lugar
        dispatch(navigateToProductPage({ nav_url: '', nav_id: '' }));
    }, [product_url, products]);

    // cambios en reseñas de producto
    useEffect(() => {
        if (reviews.length > 0) { // existen reseñas
            let avg = 0; // promedio reseñas
            let count = 0 // número reseñas
            reviews.forEach(element => {
                avg += element.rating;
                count++;
            });
            // redondea promedio de reseñas
            avg = Math.round(avg / count);
            setAvgReviews(avg);
            reviews.map(({ username }) => (
                // ya ha realizado reseña
                username === logged_user && setEnabled(false)
            ));
        }
    }, [reviews]);

    // producto agregado a carrito
    useEffect(() => {
        if (action !== '') {
            showDialog();
            dispatch(shopClearAction(''));
        }
    }, [action]);

    const showDialog = async () => {
        // filtrar promociones de producto agregado al carrito
        const ActivePromotions = promotions.filter(
            ({ variant_a, variant_b, variant_c }) =>
                [variant_a._id, variant_b._id, variant_c._id].includes(prod_var_id)
        );

        // mensaje con promociones
        let txt = ActivePromotions.length > 0 ?
            `<p style="text-align: start; font-size: 0.8em">${
                ActivePromotions.length > 1 ? 'Promociones' : 'Promoción'
            } en tienda:</p>`
        :
            '';
        if (ActivePromotions.length > 0) {
            txt += '<ul>';
            for (const e of ActivePromotions) {
                txt += `<li style="font-size:0.8em">${e.info}.</li>`;
            };
            txt += '</ul>';
        };

        let html = '<h6>¿Desear proceder al carrito?</h6><br>'; // action === success
        if (action === 'exceeds-stock')
            html = '<h6>Hicimos ajustes en su carrito dado que supera existencias en producto. ¿Desear proceder al carrito?</h6><br>';
        else if (action === 'promo-limit-2x1')
            html = '<h6>Debido a promoción 2x1, mantiene sólo 2 unidades en carrito. ¿Desear proceder al carrito?</h6><br>';
        else if (action === 'promo-limit-4x3')
            html = '<h6>Debido a promoción 4x3, mantiene sólo 4 unidades en carrito. ¿Desear proceder al carrito?</h6><br>';
        html += txt;
        if (await cartDialog('Producto agregado', html, 'Mi carrito', 'Seguir comprando')) {
            navigate('/carrito');
            setValue('/carrito');
        }
    };

    // cantidad de producto a agregar
    const handleChangeQty = ({ target }) => {
        const value = target.value;
        const _num = Number(target.value);
        if (value.trim() === '' ||
            (regex_num.test(value) && _num > 0))
            setQty(value);
    };

    // agregar al carrito
    const handleSubmit = (event) => {
        event.preventDefault();
        const _qty = Number(qty);
        if (_qty > 0) {
            if (stock <= 0)
                basicMsg('No hay stock disponible');
            else {
                const model = {
                    prod_id, prod_var_id, quantity: _qty,
                    refrigerated, medicine
                };
                dispatch(saveItemToCart(model, 'dialog'));
                // Tag Manager
                window.dataLayer.push({
                    event: 'add-to-cart'
                });
            }
        }
    };

    // variante seleccionada
    const variantSelect = VariantModel => () => {
        const {
            _id: prod_var_id, variant_title, price,
            iva, stock, barcode, images, item_promotion
        } = VariantModel;

        setVariant({ ...VariantModel, checked: true });
        // nueva variante vista
        dispatch(shopProductViewed(account_id,
            {
                prod_id, prod_var_id,
                title, variant_title, url,
                price: ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo)
                    ? item_promotion.price
                    : price,
                iva, stock, images,
                reviews, item_promotion
            }
        ));

        // actualizar URL
        navigate(`${pathname}?variant=${barcode}`, { replace: true });
        if (images.length > 0)
            setCurrentImages(images.map(e =>
            ({
                original: `${GOOGLE_DRIVE_CDN}${e.id}`,
                thumbnail: `${GOOGLE_DRIVE_CDN}${e.id}`
            })
            ));
        else
            setCurrentImages([{
                original: no_image,
                thumbnail: no_image
            }]);
    };

    // abre modal para añadir comentario
    const openModal = () => {
        dispatch(commentModalToggle(openAdd));
    };

    //Show more or less
    const [itemsToShow, setItemsToShow] = useState(5);
    const showmore = () => {
        setItemsToShow(itemsToShow + 5)
    }
    const showless = () => {
        setItemsToShow(5);
        handleScrollTo();
    }

    const ref = useRef(null);

    const handleScrollTo = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // const per = (price / 100) * 10;
    // const [time, setTime] = useState(3)
    // const handleChangeTime = (e) => {
    //     setTime(e.target.value)
    // }

    // const [tab, setTab] = useState("1");

    // const handleChangeTab = (event, newValue) => {
    //     setTab(newValue);
    // };


    return (
        prod_id !== '' ? (
            <>
                {/* Contenido */}
                <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit"
                            to="/tienda">Tienda
                        </Link>
                        {
                            category !== '' &&
                                <Link underline="hover" color="inherit"
                                    to={`/tienda/categoria/${categoryUrl}`}>
                                    { category }
                                </Link>
                        }
                        <Typography color="text.primary">{title}</Typography>
                    </Breadcrumbs>
                    <Grid container columnSpacing={4} sx={{ mt: 5 }}>
                        {/* Galeria del producto */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='product_gallery'>
                                <div className='img_product'>
                                    <ImageGallery items={currentImages} showPlayButton={false} useBrowserFullscreen={false}
                                        showFullscreenButton={false} thumbnailPosition={matches_2 ? 'bottom' : 'left'} showNav={false}
                                        renderItem={
                                            (item) =>
                                                <ReactImageMagnify
                                                    {...{
                                                        smallImage: {
                                                            alt: 'nfps_prod',
                                                            isFluidWidth: true,
                                                            src: item.original,
                                                        },
                                                        largeImage: {
                                                            src: item.original,
                                                            width: 1200,
                                                            height: 1200,
                                                        },
                                                        enlargedImagePortalId: 'portal',
                                                        enlargedImageContainerDimensions: {
                                                            width: '150%',
                                                            height: '135%'
                                                        }
                                                    }}
                                                />
                                        }
                                    />
                                </div>

                                {
                                    item_promotion.promo !== '' &&
                                    <>
                                        {
                                            ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                ?
                                                <div className="product_top-image">
                                                    <img src={shape} alt={shape} width='90' style={{ pointerEvents: 'none' }} />
                                                    <div className="product_centered_image">
                                                        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                            {item_promotion.text}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                :
                                                <div className="product_top-image">
                                                    <img src={shape} alt={shape} width='90' style={{ pointerEvents: 'none' }} />
                                                    <div className="product_centered_image">
                                                        <Typography className='no_promotion_text' sx={{ color: '#fff', fontSize: '14px', fontWeight: 'bold' }}>
                                                            Promoción
                                                        </Typography>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                                <Typography
                                sx={{mt: 1.5, fontWeight: '500'}}
                                    variant='body1'
                                    textAlign='center'>
                                    {matches ? 'Mantén tu dedo sobre la imagen para hacer zoom' : 'Pasa el mouse encima de la imagen para aplicar zoom'}
                                </Typography>
                            </div>
                        </Grid>

                        {/* Producto (Nombre, precio, cantidad, add-cart) */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div id='portal'></div>
                            
                            {/* Nombre del producto */}
                            <Typography variant="h5" sx={{ fontWeight: '500', mt: { xs: 1, sm: 1, md: 0 } }}>{title}</Typography>
                            {/* Fin Nombre del producto */}

                            {/* Rating del producto */}
                            <Box sx={{ mt: .5 }}>
                                {
                                    reviews.length > 0 ?
                                        <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}>
                                            <div className="ratings-product">
                                                {
                                                    Array.from(Array(avgReviews), (e, j) => {
                                                        return <i className="fas fa-star"
                                                            key={j}
                                                            style={{
                                                                marginRight: '4.5px',
                                                                color: '#FFA41C',
                                                                stroke: "#FFA41C",
                                                            }}></i>
                                                    })
                                                }
                                            </div>
                                            <Box sx={{ ml: 1.5, mt: .5 }}>
                                                <Typography sx={{ fontSize: '12px', color: '#707070', fontWeight: '600' }}>({reviews.length} comentarios)</Typography>
                                            </Box>
                                        </Box>

                                        :
                                        <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}>
                                            <div className="ratings-product">
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                            <Box sx={{ ml: 1.5, mt: .5 }}>
                                                <Typography sx={{ fontSize: '12px', color: '#707070', fontWeight: '600', cursor: 'pointer' }}
                                                    onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}
                                                >
                                                    (0 comentarios)
                                                </Typography>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                            {/* Fin Rating del producto */}

                            {/* Precio */}
                            {
                                ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                                    <Stack direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        spacing={2}>
                                        <Typography variant="h5" sx={{
                                            color: '#000000', fontWeight: 'normal', mt: 1.5,
                                            textDecoration: 'line-through'
                                        }}>
                                            ${price}
                                        </Typography>
                                        <Typography variant="h5" sx={{ color: '#F90606', fontWeight: 'normal', mt: 1.5 }}>
                                            ${item_promotion.price}
                                        </Typography>
                                    </Stack>
                                    :
                                    <Typography variant="h5" sx={{ color: '#F90606', fontWeight: 'normal', mt: 1.5 }}>
                                        ${price}
                                    </Typography>
                            }
                            {/* Fin Precio */}

                            {/* No se elimina de momento (Planea y ahorra) */}
                            {/* <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="basic tabs example" centered variant='fullWidth'>
                                        <Tab label="Planea y ahorra" value="1" wrapped />
                                        <Tab label="Compra por única vez" value="2" wrapped />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ p: 0, m: 0 }}>
                                    <Box sx={{ mt: 1.5, mb: 2.5 }}>
                                        {
                                            prod_variants.length > 1 &&
                                            <>
                                                <Typography variant='body1' gutterBottom>Tipos:</Typography>
                                                <Stack spacing={1.5}>
                                                    {
                                                        prod_variants.map(e => {
                                                            return (
                                                                <Button
                                                                    key={e._id}
                                                                    variant={prod_var_id === e._id ? "contained" : "outlined"}
                                                                    onClick={variantSelect(e)}
                                                                    sx={{ textTransform: 'lowercase' }}
                                                                >
                                                                    {e.variant_title}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </>
                                        }
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                                        <Typography variant='h6' sx={{ fontWeight: '600', my: 'auto' }}>Planea y ahorra</Typography>
                                        <Chip label="10%" sx={{ p: '5px', fontWeight: 'bold', my: 'auto' }} color='primary'></Chip>
                                    </Box>
                                    <Typography sx={{ fontSize: '18px', color: '#F90606', fontWeight: '600' }} gutterBottom>
                                        ${ price - per }
                                    </Typography>
                                    <Typography variant='body1' paragraph>
                                        Conoce los beneficios del programa planea y ahorra. <Link to="/">Más información</Link>
                                    </Typography>
                                    <form>
                                        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start', gap: '15px' }}>
                                            <FormControl sx={{ width: '200px' }}>
                                                <InputLabel>Cantidad</InputLabel>
                                                <Select
                                                    labelId="cantidad_label"
                                                    id="label_input"
                                                    value={qty}
                                                    label="Cantidad"
                                                    onChange={handleChangeQty}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ width: '200px' }}>
                                                <InputLabel>Entrega cada</InputLabel>
                                                <Select
                                                    labelId="cantidad_label"
                                                    id="label_input"
                                                    value={time}
                                                    label="Entrega cada"
                                                    onChange={handleChangeTime}
                                                >
                                                    <MenuItem value={1}>2 semanas</MenuItem>
                                                    <MenuItem value={2}>3 semanas</MenuItem>
                                                    <MenuItem value={3}>cada mes (Recomendado)</MenuItem>
                                                    <MenuItem value={4}>5 semanas</MenuItem>
                                                    <MenuItem value={5}>6 semanas</MenuItem>
                                                    <MenuItem value={6}>7 semanas</MenuItem>
                                                    <MenuItem value={7}>2 meses</MenuItem>
                                                    <MenuItem value={8}>3 meses</MenuItem>
                                                    <MenuItem value={9}>4 meses</MenuItem>
                                                    <MenuItem value={10}>6 meses</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box sx={{ mt: 2 }}>
                                            <Button variant='contained'>Configurar ahora</Button>
                                        </Box>
                                    </form>
                                </TabPanel>

                                <TabPanel value="2" sx={{ p: 0, m: 0 }}>
                                    <Box sx={{ mt: 1.5, mb: 2.5 }}>
                                        {
                                            prod_variants.length > 1 &&
                                            <>
                                                <Typography variant='body1' gutterBottom>Tipos:</Typography>
                                                <Stack spacing={1.5}>
                                                    {
                                                        prod_variants.map(e => {
                                                            return (
                                                                <Button
                                                                    key={e._id}
                                                                    variant={prod_var_id === e._id ? "contained" : "outlined"}
                                                                    onClick={variantSelect(e)}
                                                                    sx={{ textTransform: 'lowercase' }}
                                                                >
                                                                    {e.variant_title}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </>
                                        }
                                    </Box>

                                    <form onSubmit={handleSubmit}>
                                        <FormControl sx={{ width: '200px' }}>
                                            <InputLabel>Cantidad</InputLabel>
                                            <Select
                                                labelId="cantidad_label"
                                                id="label_input"
                                                value={qty}
                                                label="Cantidad"
                                                onChange={handleChangeQty}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography variant="caption" display="block" sx={{ mt: .5 }}>
                                            ({stock} disponibles)
                                        </Typography>

                                        <Button type="submit" variant='contained' sx={{ mt: 2, fontWeight: 'bold' }} disabled={stock === 0}>Agregar al carrito</Button>
                                    </form>
                                </TabPanel>
                            </TabContext> */}

                            {/* Variantes */}
                            <Box sx={{ mt: 1.5, mb: 2.5 }}>
                                {
                                    prod_variants.length > 1 &&
                                    <>
                                        <Typography variant='body1' gutterBottom>Tipos:</Typography>
                                        <Stack spacing={1.5}>
                                            {
                                                prod_variants.map(e => {
                                                    return (
                                                        <Button
                                                            key={e._id}
                                                            variant={prod_var_id === e._id ? "contained" : "outlined"}
                                                            onClick={variantSelect(e)}
                                                            sx={{ textTransform: 'lowercase' }}
                                                        >
                                                            {e.variant_title}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </>
                                }
                            </Box>
                            {/* Fin Variantes */}

                            {/* Cantidad y add-cart */}
                            <form onSubmit={handleSubmit}>
                                {
                                    (!refrigerated && !medicine) &&
                                    <>
                                        <FormControl sx={{ width: '200px' }}>
                                            <TextField
                                                label="Cantidad"
                                                variant="outlined"
                                                value={qty}
                                                size="small"
                                                inputProps={{ maxLength: 4 }}
                                                onChange={handleChangeQty}
                                            />
                                        </FormControl>
                                        <Typography variant="caption" display="block" sx={{ mt: .5 }}>
                                            ({stock} disponibles)
                                        </Typography>
                                    </>
                                }
                                {
                                    (refrigerated || medicine) ?
                                        <Box sx={{ my: 3 }}>
                                            <Typography variant="body1" paragraph>
                                                Producto no disponible en tienda en línea, sin embargo, puedes ADQUIRIRLO por teléfono o escribiéndonos por WhatsApp.
                                            </Typography>
                                            <Stack direction={matches_1 ? 'column' : 'row'} spacing={2}>
                                                <WhatsAppButton
                                                    variant='contained'
                                                    startIcon={<WhatsAppIcon />}
                                                    fullWidth
                                                    LinkComponent='a'
                                                    href={
                                                        `https://api.whatsapp.com/send?phone=523324966190&text=Hola,+quisiera+adquirir+${variant_title ?
                                                            `${title}+${variant_title}`.trim().replace(/\s/g, '+')
                                                            :
                                                            `${title}`.trim().replace(/\s/g, '+')
                                                        }+(${qty > 1 ? `${qty}+piezas` : '1+pieza'
                                                        })`
                                                    }
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    WhatsApp
                                                </WhatsAppButton>
                                                <PhoneButton
                                                    variant='contained'
                                                    startIcon={<PhoneIcon />}
                                                    fullWidth
                                                    LinkComponent='a'
                                                    href='tel:3324966190'
                                                >
                                                    Llamar
                                                </PhoneButton>
                                            </Stack>
                                        </Box>
                                        :
                                        <Button type="submit"
                                            variant='contained'
                                            sx={{ mt: 2, fontWeight: 'bold' }}>
                                            Agregar al carrito
                                        </Button>
                                }
                            </form>
                            {/*Fin Cantidad y add-cart */}

                            {/* Categorias y etiquetas */}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant='body1'>Categorías:
                                    {
                                        product_categories.map((e, i) =>
                                            <Link key={i}
                                                style={{ marginLeft: '3px' }}
                                                to={
                                                    `/tienda/categoria/${
                                                        e.replace(/\s+/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                                    }`
                                                }>
                                                {
                                                    i === product_categories.length - 1
                                                    ? e : `${e}, `
                                                }
                                            </Link>
                                        )
                                    }
                                </Typography>
                            </Box>
                            {/* fin Categorias y etiquetas */}

                            {/* Descripción */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ mt: 2.5 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                        Descripción
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* información de envíos */}
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>Información de envíos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        Nuestro servicio de envíos se encuentra disponible únicamente en México.
                                    </Typography>
                                    <Typography paragraph>
                                        El tiempo de entrega podrá ser de <b>1 a 7 días hábiles</b>, contados a partir de
                                        que se ha recibido el importe completo de la compra. El tiempo de entrega
                                        dependerá del código postal del destino y de la paquetería. En el caso de
                                        códigos postales de zonas extendidas, la garantía de entrega no podrá
                                        aplicarse.
                                    </Typography>
                                    <Typography paragraph>
                                        Si tu código postal tiene cobertura con entrega a domicilio, el paquete se te
                                        entregará hasta la puerta de tu casa. Si, por el contrario, tu domicilio es de
                                        zona extendida o de difícil acceso, el paquete llegará a la oficina ocurre más
                                        cercana y tendrás cierto tiempo para recoger el pedido.
                                    </Typography>
                                    <Typography>
                                        Para las compras mayores a 1500 pesos, o en artículos seleccionados, el
                                        envío no tendrá costo. La elección de la paquetería será la que
                                        consideremos más adecuada y tenga la cobertura hacia su código postal.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* Facturación */}
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                        Facturación
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Puedes solicitar una factura electrónica para todos tus pedidos en un plazo no mayor a 48hrs. Para hacerlo sólo debes llenar todos tus datos en el apartado de facturación del checkout, adjuntando tu Constancia de Situación Fiscal actualizada.
                                        {/* Para más información consulta nuestra política de facturación aquí (enlace a política de facturación). */}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* Métodos de pago */}
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                        Métodos de pago
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img src={payments} width='100%' alt="" />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* Producto (Nombre, precio, cantidad, add-cart) */}
                    </Grid>
                </Container>

                <Container maxWidth="xl" sx={{ mt: 6 }} className="last-view">
                    {/* Propuesta de valor */}
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3} sx={{ bgcolor: '#E5E7E9', textAlign: 'center', p: 2.5, position: 'relative' }}>
                                        <Box className='vertical-center'>
                                            <CreditCardIcon sx={{ fontSize: '2rem' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9} sx={{ bgcolor: '#F4F6F6', p: 2.5 }}>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                            Paga en linea y recibe en casa
                                        </Typography>
                                        <Typography variant='body1'>
                                            Todos los medios de pago
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3} sx={{ bgcolor: '#E5E7E9', textAlign: 'center', p: 2.5, position: 'relative' }}>
                                        <Box className='vertical-center'>
                                            <LocalShippingIcon sx={{ fontSize: '2rem' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9} sx={{ bgcolor: '#F4F6F6', p: 2.5 }}>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                            Envío gratis
                                        </Typography>
                                        <Typography variant='body1'>
                                            *Compras mayores a $1,500
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3} sx={{ bgcolor: '#E5E7E9', textAlign: 'center', p: 2.5, position: 'relative' }}>
                                        <Box className='vertical-center'>
                                            <LockIcon sx={{ fontSize: '2rem' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9} sx={{ bgcolor: '#F4F6F6', p: 2.5 }}>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                            Compras Seguras
                                        </Typography>
                                        <Typography variant='body1'>
                                            Compras protegidas siempre
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* Fin propuesta de valor */}

                    {/* Descripción del producto */}
                    {
                        info !== ''
                            ?
                            <Box sx={{ mt: 6 }}>
                                <Typography sx={{ fontWeight: '600', textTransform: 'uppercase', fontSize: '28px' }}>
                                    Información adicional
                                </Typography>
                                <Typography variant='body1'>{info}</Typography>
                            </Box>
                            :
                            null
                    }

                    {/* Producto en compras frecuentes */}
                    <ProductsFBT
                        fbtItems={fbtItems}
                        setFBTItems={setFBTItems}
                        product={product}
                        variant={variant}
                        setVariant={setVariant} />
                    {/* Fin Producto en compras frecuentes */}

                    {/* Upsell */}
                    {/* <Box sx={{ mt: '60px' }}>
                        <Box sx={{ mb: 1.5, display: 'flex', justifyContent: 'space-between', gap: '10px', flexWrap: 'wrap' }}>
                            <Box sx={{ my: 'auto' }}>
                                <Typography variant='h5' color='text.primary'>También te recomendamos</Typography>
                            </Box>
                            <Stack direction='row' spacing={1.2} justifyContent="flex-end" sx={{ my: 'auto' }}>
                                <IconButton className='upsell-swiper-button-prev' sx={{ bgcolor: '#F2F3F4', '&:hover': { bgcolor: '#E1E1E1' } }} size='small'>
                                    <ChevronLeftIcon />
                                </IconButton>
                                <IconButton className='upsell-swiper-button-next' sx={{ bgcolor: '#F2F3F4', '&:hover': { bgcolor: '#E1E1E1' } }} size='small'>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                700: {
                                    slidesPerView: 4,
                                },
                                1000: {
                                    slidesPerView: 4,
                                },
                            }}
                            spaceBetween={20}
                            navigation={{
                                nextEl: '.upsell-swiper-button-next',
                                prevEl: '.upsell-swiper-button-prev',
                            }}
                            loop={true}
                            modules={[Navigation]}
                        >
                            {
                                productos.map((item) => (
                                    <SwiperSlide key={item.id} style={{ textAlign: 'center' }}>
                                        <Link to=''>
                                            <img src={item.img} alt={item.img} width='100%' />
                                        </Link>
                                        <Typography variant='body1' gutterBottom>{item.name}</Typography>
                                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <div className="ratings-shop">
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                </div>
                                            </Box>
                                        </Box>
                                        <Typography variant='body1' sx={{ color: '#F90606', fontWeight: '600' }} gutterBottom>
                                            ${ item.price }
                                        </Typography>
                                        <Button variant='contained' sx={{ textTransform: 'none' }}>Añadir al carrito</Button>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Box> */}
                    {/* Fin upsell */}

                    {/* Vistos recientemente */}
                    <Box sx={{ mt: 5 }}>
                        <RecentlyViewed product_id={prod_id} />
                    </Box>

                    {/* Comentarios */}
                    <Box sx={{ mt: 6 }} ref={ref}>
                        <Typography variant='h5' gutterBottom>Comentarios de clientes</Typography>
                        {
                            account_id !== '0' ?
                                enabled &&
                                <Button variant="contained"
                                    onClick={openModal}>
                                    Agregar comentario
                                </Button>
                                :
                                <Stack direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2} sx={{ 'fontStyle': 'italic' }}>
                                    <Typography variant="body2" gutterBottom>
                                        Es necesario estar registrado para añadir un comentario
                                    </Typography>
                                    <Button variant="contained"
                                        onClick={() => navigate("/signup", { state: { page: `tienda/producto/${product_url}` } })}>
                                        Registrarme
                                    </Button>
                                </Stack>
                        }
                        {/* Reviews */}
                        {
                            reviews.slice(0, itemsToShow).map(e =>
                                <ProductReview key={e._id} review={e} prod_id={prod_id} />
                            )
                        }
                        {
                            reviews.length > 5
                                ?
                                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                    {
                                        (itemsToShow < reviews.length)
                                            ?
                                            <Button variant="outlined" onClick={showmore}>Mostrar más</Button>
                                            :
                                            <Button variant="outlined" onClick={showless}>Mostrar menos</Button>
                                    }
                                </div>
                                :
                                null
                        }
                        {/* Reviews */}

                    </Box>
                    {/* Fin Comentarios */}
                </Container>
                {/* Fin Contenido */}

                <ReviewModal prod_id={prod_id} />
            </>
        )
            :
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>
                <CircularProgress />
            </Backdrop>
    );
};

export default ProductSample;