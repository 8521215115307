import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
// import TagManager from 'react-gtm-module';

import { NavContext } from '../../../../context/NavContext';
import { saveItemToCart, shopClearAction } from '../../../../actions/shop';
import no_image from '../../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

//MUI
import {
    Alert, Button, Card, CardActions, CardContent, CardMedia,
    Grid, Typography, Stack
} from '@mui/material';
import { styled } from "@mui/material/styles";

const ButtonGreen = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    fontWeight: '600',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

// const tagManagerArgs = {
//     gtmId: 'GTM-KQMW2JG'
// };
// TagManager.initialize(tagManagerArgs);

const BuyAgain = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setValue } = useContext(NavContext);

    // productos de tienda y datos de navegación
    const { action, products } = useSelector(state => state.shop);

    const { buyAgainProducts } = useSelector(state => state.user_account);
    const [item, setItem] = useState([]);
    
    useEffect(() => {
        // cliente tiene compras
        if (buyAgainProducts.length > 0) {
            // productos que ha comprado
            const filtered = products.filter(e => e.prod_variants.filter(u => buyAgainProducts.includes(u._id)).length > 0);
            // obtenemos solamente variante y producto (se refleja como item independiente)
            if (filtered.length > 0) {
                let MyProducts = [];
                
                filtered.map(e => {
                    const { _id: prod_id, prod_variants, ...myProduct } = e;
                    
                    for (const variant of prod_variants) {
                        const { _id: prod_var_id, ...myVariant } = variant;
                        if (buyAgainProducts.includes(prod_var_id))
                            MyProducts = [...MyProducts, {
                                ...myProduct,
                                ...myVariant,
                                prod_id,
                                prod_var_id
                            }];
                    };
                    return e;
                });
                setItem(MyProducts);
            }
            // setItem(products.filter(e => e.prod_variants.filter(u => buyAgainProducts.includes(u._id)).length > 0));
        }
    }, [buyAgainProducts]);

    // producto agregado a carrito
    useEffect(() => {
        if (action !== '') {
            let text = '¿Desear proceder al carrito?';
            if (action === 'exceeds-stock')
                text = 'Hicimos ajustes en su carrito dado que supera existencias en producto. ¿Desear proceder al carrito?';
            else if (action === 'promo-limit-2x1')
                text = 'Debido a promoción 2x1, mantiene sólo 2 unidades en carrito. ¿Desear proceder al carrito?';
            else if (action === 'promo-limit-4x3')
                text = 'Debido a promoción 4x3, mantiene sólo 4 unidades en carrito. ¿Desear proceder al carrito?';
            Swal.fire({
                title: 'Producto agregado',
                text,
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Mi carrito',
                cancelButtonText: 'Seguir comprando',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/carrito');
                    setValue('/carrito');
                }
            });
            dispatch(shopClearAction(''));
        }
    }, [action]);
    
    // agregar producto a carrito
    const addToCart = _item => () => {
        const { prod_id, prod_var_id, medicine, refrigerated } = _item;
        
        const model = {
            prod_id, prod_var_id, quantity: 1,
            refrigerated, medicine
        };
        dispatch(saveItemToCart(model, 'dialog'));
        // Tag Manager
        // window.dataLayer.push({
        //     event: 'add-to-cart'
        // });
    };

    return (
        <>
            {
                item.length > 0
                    ?
                    <Grid container spacing={4}>
                        {
                            item.map((item) => (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}
                                    key={ item.prod_var_id } sx={{ display: 'flex' }}>
                                    <Card sx={{ width: '100%', position: 'relative', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 160, height: 160, objectFit: 'cover', mx: 'auto', mt: 3 }}
                                            image={
                                                item.images.length > 0 ?
                                                    `${GOOGLE_DRIVE_CDN}${item[0].id}`
                                                :
                                                    no_image
                                            }
                                            title={item.img}
                                        />
                                        <CardContent sx={{ mb: 6 }}>
                                            <Typography variant='body2' gutterBottom>
                                                { `${item.title} ${item.variant_title}`.trim() }
                                            </Typography>
                                            {
                                                item.item_promotion &&
                                                    ['Descuento$Producto', 'Descuento%Producto'].includes(
                                                        item.item_promotion.promo
                                                    ) ?
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="baseline"
                                                        spacing={1}>
                                                        <Typography variant="body1"
                                                            sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                            ${item.price}
                                                        </Typography>
                                                        <Typography variant="body1"
                                                            sx={{ fontWeight: '600', color: '#F90606' }}>
                                                            ${item.item_promotion.price}
                                                        </Typography>
                                                    </Stack>
                                                :
                                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                                        ${item.price}
                                                    </Typography>
                                            }
                                        </CardContent>
                                        <CardActions sx={{ position: 'absolute', bottom: 0, width: '100%', px: 2 }}>
                                            <ButtonGreen size="small" variant='contained'
                                                sx={{ width: '100%', textTransform: 'none' }}
                                                onClick={ addToCart(item) }>
                                                Agregar al carrito
                                            </ButtonGreen>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                    :
                    <Alert severity="warning">Aún no cuentas con ninguna compra - <Link to='/tienda'>Comenzar a comprar</Link></Alert>
            }
        </>
    );
};

export default BuyAgain;