import Swal from 'sweetalert2';

import { types } from '../types/types';

import { protectedReq, request } from '../helpers/requestHelper';
import { basicMsg, basicTimerMsg, htmlMsg, msg } from './swal_msg';
import { shopProductsFBTAdded, shopSnackbarToggle, uiLoading1 } from './ui';

// recuperar los productos de carrito
// first_loading => primera consulta (cargar productos)
export const getCartItems = (first_loading) => {
    return async(dispatch) => {
        try {
            dispatch(uiLoading1(true));
            // token sesión usuario
            const token = localStorage.getItem('token') || '';
            // token carrito
            const cart_token = localStorage.getItem('cart-token') || '';
            // token (recently viewed products)
            const rvp_token = localStorage.getItem('rvp-token') || '';
            // cupón
            const coupon_name = localStorage.getItem('coupon') || '';
            const resp = await request('shop/cart/load', {
                token, cart_token, rvp_token, coupon_name, first_loading
            }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { op_msg } = body; // cambios de estado en front
                const { cart_msg, new_cart_token, ...cartData } = body.resp;
                const { coupon } = cartData;
                
                // estatus de cupón
                if (coupon && coupon.name) // aplicado
                    localStorage.setItem('coupon', coupon.name);
                else // inválido/removido
                    localStorage.removeItem('coupon');
                
                // sesión expirada
                if (op_msg.includes('not-logged-in'))
                    localStorage.removeItem('token');

                // guardar nuevo token de carrito
                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', new_cart_token);

                printCartMessages(cart_msg);
                
                dispatch(loadCart(cartData));
            } else {
                // GUARDAR PRODUCTOS DE TIENDA
                if (body.msg === 'server-err')
                    msg('warning', 'Carrito',
                    'No se pudo recuperar el carrito. Recargue la página');
                else if ([
                        'no-token', 'jwt-error', 'cart-not-found'
                    ].includes(body.msg))
                    dispatch(emptyCart());
                // eliminar token de sesión y carrito
                else if (body.msg === 'remove-cart') {
                    localStorage.removeItem('token');
                    dispatch(emptyCart());
                } else if (body.msg === 'summary-err')
                    msg('warning', 'Carrito',
                    'No hemos podido cargar el carrito');
                else if (body.msg === 'coupon-not-found')
                    msg('warning', 'Carrito',
                    'Cupón no existe');
            }
            dispatch(uiLoading1(false));
        } catch (err) {
            msg('warning', 'Carrito',
            'Incidente al recuperar el carrito');
            dispatch(uiLoading1(false));
        };
    };
};

// agregar producto a carrito
export const saveItemToCart = (item, flag) => {
    return async(dispatch) => {
        try {
            // token sesión usuario
            const token = localStorage.getItem('token') || '';
            // token carrito
            const cart_token = localStorage.getItem('cart-token') || '';
            // cupón
            const coupon_name = localStorage.getItem('coupon') || '';

            const resp = await protectedReq('shop/cart/add-item', {
                item, token, cart_token, coupon_name
            }, 'POST');
            const body = await resp.json();
            if (body.status) {
                // msg = success || exceeds-stock || promo-limit-2x1 || promo-limit-4x3
                const { op_msg, msg } = body;
                const { new_cart_token, ...cartData } = body.resp;
                const { coupon } = cartData;

                // se inicializa carrito
                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', new_cart_token);

                // estatus de cupón
                if (coupon && coupon.name) // aplicado
                    localStorage.setItem('coupon', coupon.name);
                else // inválido/removido
                    localStorage.removeItem('coupon');
                
                dispatch(loadCart(cartData));
                dispatch(shopClearAction(
                    flag === 'dialog' ? msg : ''
                ));
            } else {
                if (body.msg === 'cart-not-saved') {
                    msg('warning', 'Carrito',
                    'No se ha podido guardar su carrito');
                    dispatch(emptyCart());
                } else if (body.msg === 'server-err')
                    msg('warning', 'Carrito',
                    'No se pudo agregar el producto. Recargue la página');
                else if (body.msg === 'variant-not-found')
                    msg('info', 'Carrito',
                    'No se encontró el producto en tienda');
                else if (body.msg === 'summary-err')
                    msg('warning', 'Carrito',
                    'No se pudo agregar el producto al carrito');
                else if (body.msg === 'no-stock')
                    msg('info', 'Carrito',
                    'No hay existencias suficientes');
                else if (body.msg === 'miss')
                    msg('info', 'Carrito',
                    'Información del producto incompleta');
                else if (body.msg === 'not-available') {
                    const { title, variant_title, quantity } = item;
                    const fullTitle = variant_title ?
                        `${title}+${variant_title}`.trim().replace(/\s/g, '+')
                    :
                        `${title}`.trim().replace(/\s/g, '+');
                    const msg = quantity > 1 ? `${quantity}+piezas` : '1+pieza';
                    const html = `<p>No es posible adquirir este producto en tienda en línea, comuníquese con nosotros por <a href="https://api.whatsapp.com/send?phone=523324966190&text=Hola,+quisiera+adquirir+${fullTitle}+(${msg})" target="_blank" rel="noopener noreferrer">whatsapp</a></p>`;
                    Swal.fire({
                        title: 'Carrito',
                        icon: 'warning',
                        html,
                        focusConfirm: true,
                        confirmButtonText: 'Entendido'
                    });
                }
            }
        } catch (err) {
            msg('warning', 'Carrito',
            'Incidente al agregar producto');
        };
    };
};

// limpiar acción (state)
export const shopClearAction = (payload) => ({
    type: types.shopClearAction,
    payload
});

// actualizar cantidad de producto en carrito
export const updateItemInCart = (prod_var_id, quantity) => {
    return async(dispatch) => {
        try {
            dispatch(uiLoading1(true));

            // token carrito
            const token = localStorage.getItem('cart-token') || '';
            // cupón
            const coupon_name = localStorage.getItem('coupon') || '';
            
            const resp = await request('shop/update-cart', {
                token, prod_var_id, quantity, coupon_name
            }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const {  cart_msg, ...cartData } = body.resp;
                const { coupon } = cartData;

                // estatus de cupón
                if (coupon && coupon.name) // aplicado
                    localStorage.setItem('coupon', coupon.name);
                else // inválido/removido
                    localStorage.removeItem('coupon');
                    
                dispatch(loadCart(cartData));
                printCartMessages(cart_msg);
            } else {
                if (body.msg === 'invalid-token') {
                    msg('warning', 'Carrito',
                    'Su carrito ha expirado');
                    dispatch(emptyCart());
                } else if (body.msg === 'cart-not-found') {
                    msg('warning', 'Carrito',
                    'No hemos podido cargar su carrito. Recargue la página');
                    dispatch(emptyCart());
                } else if (body.msg === 'no-stock') 
                    msg('info', 'Carrito',
                    'No hay más existencias de producto');
                else if (body.msg === 'not-found') 
                    msg('info', 'Carrito',
                    'El producto ya no está disponible');
                else if (body.msg === 'promo-limit-2x1')
                msg('info', 'Promoción 2x1',
                    'Debido a promoción, no puede añadir más de 2 productos');
                else if (body.msg === 'promo-limit-4x3')
                    msg('info', 'Promoción 4x3',
                    'Debido a promoción, no puede añadir más de 4 productos');
                else if (body.msg === 'server-err')
                    msg('error', 'Carrito',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else
                    msg('warning', 'Carrito',
                    'Inténtelo de nuevo, por favor');
            }
            dispatch(uiLoading1(false));
        } catch (err) {
            msg('warning', 'Carrito',
            'No puede agregar más producto');
            dispatch(uiLoading1(false));
        }
    }
};

// actualizar producto (state)
export const updateQuantity = (payload) => ({
    type: types.shopUpdateQuantity,
    payload
});

// eliminar producto de carrito
export const removeItemFromCart = (prod_var_id) => {
    return async(dispatch) => {
        try {
            dispatch(uiLoading1(true));
            // token carrito
            const token = localStorage.getItem('cart-token') || '';
            // cupón
            const coupon_name = localStorage.getItem('coupon') || '';
            const resp = await request('shop/cart/remove-from-cart', {
                token, prod_var_id, coupon_name
            }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { cart_msg, ...cartData } = body.resp;
                const { coupon } = cartData;
                
                // estatus de cupón
                if (coupon && coupon.name) // aplicado
                    localStorage.setItem('coupon', coupon.name);
                else // inválido/removido
                    localStorage.removeItem('coupon');

                dispatch(loadCart(cartData));
                printCartMessages(cart_msg);
            } else {
                if (body.msg === 'server-err')
                    msg('warning', 'Carrito',
                    'No se pudo eliminar el producto. Recargue la página');
                else if (body.msg === 'summary-err')
                    msg('warning', 'Carrito',
                    'No hemos podido cargar el carrito');
                else if (body.msg === 'cart-not-found') {
                    dispatch(emptyCart());
                    msg('warning', 'Carrito',
                    'No se encontró su carrito de compras');
                } else if (body.msg === 'invalid-token') {
                    dispatch(emptyCart());
                    msg('warning', 'Carrito',
                    'Su carrito de compras ha expirado');
                }
            }
            dispatch(uiLoading1(false));
        } catch (err) {
            msg('warning', 'Carrito',
            'Incidente al eliminar producto');
            dispatch(uiLoading1(false));
        }
    }
};

// agregar productos comprados juntos frecuentemente al carrito
export const addItemsToCart = (items) => {
    return async(dispatch) => {
        try {
            // token sesión usuario
            const token = localStorage.getItem('token') || '';
            // token carrito
            const cart_token = localStorage.getItem('cart-token') || '';
            // cupón
            const coupon_name = localStorage.getItem('coupon') || '';

            const resp = await request('shop/cart/items-to-add',
                { token, cart_token, items, coupon_name }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { op_msg } = body;
                const {
                    noStockItems, notFoundItems,
                    refrigeratedItems, medicineItems,
                    cart_msg, new_cart_token, ...cartData
                } = body.resp;
                const { coupon } = cartData;

                if (op_msg.includes('cart-init'))
                    localStorage.setItem('cart-token', new_cart_token);
                
                // estatus de cupón
                if (coupon && coupon.name) // aplicado
                    localStorage.setItem('coupon', coupon.name);
                else // inválido/removido
                    localStorage.removeItem('coupon');
                
                dispatch(loadCart(cartData));
                dispatch(shopProductsFBTAdded(true));
                
                if (noStockItems.length > 0 || notFoundItems.length > 0 ||
                    refrigeratedItems.length > 0 || medicineItems.length > 0 ||
                    cart_msg.length > 0) {
                    htmlMsg('info', 'Carrito', itemsInfo(body.resp));
                } else
                    basicTimerMsg('Producto agregado');
            } else {
                if (body.msg === 'cart-not-saved') {
                    msg('warning', 'Carrito',
                    'No se ha podido guardar su carrito');
                    dispatch(emptyCart());
                } else if (body.msg === 'server-err') {
                    const _msg = items.length > 1 ?
                        'No se pudieron agregar los productos' : 'No se pudo agregar el producto';
                    msg('warning', 'Carrito', `${_msg}. Recargue la página`);
                } else if (body.msg === 'no-item-saved')
                    msg('info', 'Carrito',
                    'Ningún producto guardado');
                else if (body.msg === 'save-failed')
                    msg('warning', 'Carrito',
                    'No se pudo recuperar su carrito. Recargue la página');
            }
        } catch (err) {
            const txt = 
                `Incidente al agregar producto${
                    items.length > 1 ? 's' : ''
                }`;
            msg('warning', 'Carrito', txt);
        }
    }
};

// producto no guardado en carrito
function itemsInfo({
    cart_msg, savedItems,
    noStockItems, notFoundItems,
    refrigeratedItems, medicineItems
}) {
    let msg = '';
    if (savedItems.length > 0) {
        msg += 'Producto guardado:';
        for (const e of savedItems) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    if (noStockItems.length > 0) {
        msg += 'Producto sin existencias:';
        for (const e of noStockItems) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    if (notFoundItems.length > 0) {
        msg += 'Producto no encontrado en tienda:';
        for (const e of notFoundItems) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    if (refrigeratedItems.length > 0) {
        msg += 'Producto frío (adquirir por teléfono/whatsapp):';
        for (const e of refrigeratedItems) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    if (medicineItems.length > 0) {
        msg += 'Medicamentos (adquirir por teléfono/whatsapp):';
        for (const e of medicineItems) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    if (cart_msg.length > 0) {
        msg += 'Mensajes de su carrito:';
        for (const e of cart_msg) {
            msg += `<li>${e}</li>`;
        };
        msg += '<br>';
    }
    return `<div style="text-align: left; padding-left: 0pt;"><ul>${msg}</ul></div>`;
};

// carga los productos del carrito
export const loadCart = (payload) => ({
    type: types.shoppingCart,
    payload
});

// vaciar carrito, se realizó pedido / expiró carrito
const shopEmptyCart = () => ({
    type: types.shopEmptyCart
});
// eliminar carrito de local storage
export const emptyCart = () => {
    return async(dispatch) => {
        localStorage.removeItem('cart-token');
        dispatch(shopEmptyCart());
    };
};

// cupón aplicado en /carrito
export const setCoupon = (payload) => ({
    type: types.shopCoupon,
    payload
});

// recupera =>
// productos de tienda / vistos recientemente / destacados
export const shopProducts = (origin_page) => {
    // origin_page = shop || shop-category
    return async(dispatch) => {
        try {
            // token (recently viewed products)
            const rvpToken = localStorage.getItem('rvp-token') || '';
            const resp = await request('shop/products', { rvpToken }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { resp, op_msg } = body;
                // token no válido
                if (op_msg.includes('no-token'))
                    localStorage.removeItem('rvp-token');

                dispatch(productsLoaded(resp));
            }
        } catch (err) {
            dispatch(shopSnackbarToggle({
                status: true,
                origin_page,
                err_msg: 'No se pudieron cargar los productos'
            }));
        }
    }
};

// cargar productos en tienda
const productsLoaded = (payload) => ({
    type: types.shopProductsLoaded,
    payload
});

// recupera categorías / banners / métodos de envío Tienda ONL /
// categorías y publicaciones de blog
export const loadShop = () => {
    return async(dispatch) => {
        try {
            const resp = await request('shop/start');
            const body = await resp.json();
            if (body.status) {
                const { categories, banners, shipping, blog } = body.resp;
                dispatch(shopInfoLoaded({
                    categories, banners, shipping
                }));
                dispatch(userBlogLoaded(blog));
            } else {
                if (body.msg === 'server-err')
                    basicMsg('Incidente al cargar tienda');
            }
        } catch (err) {
            basicTimerMsg('Incidente');
        }
    }
};

// información de tienda recuperada
const shopInfoLoaded = (payload) => ({
    type: types.shopInfoLoaded,
    payload
});
// información de blog recuperada
const userBlogLoaded = (payload) => ({
    type: types.userBlogLoaded,
    payload
});

// cargar lista de cupones
export const shopCoupons = () => {
    return async(dispatch) => {
        try {
            const resp = await request('shop/coupons');
            const body = await resp.json();
            if (body.status) {
                if (body.resp.length > 0)
                    dispatch(couponsLoaded(body.resp));
            }
        } catch (err) {}
    }
};

// cupones para carrito recuperados
const couponsLoaded = (payload) => ({
    type: types.shopCouponsLoaded,
    payload
});

// obtiene pedido de cliente
export const getOrder = async(nefro_reference) => {
    try {
        const resp = await request('shop/client-order', { nefro_reference }, 'POST');
        const body = await resp.json();
        if (body.status) return body;
        else return { status: false };
    } catch (err) {
        msg('error', 'Pedido',
        'No se pudo recuperar su pedido');
        return { status: false };
    }
};

// navegar a página de producto y mostrar variante
export const navigateToProductPage = (payload) => ({
    type: types.shopRedirect,
    payload
});

// producto visto en tienda
export const shopProductViewed = (account_id, item) => {
    return async(dispatch) => {
        try {
            const { prod_id, prod_var_id } = item;
            // token productos vistos recientemente
            const token = localStorage.getItem('rvp-token') || '';
            const resp = await request('shop/product-viewed',
            { token, prod_id, prod_var_id, account_id }, 'POST');
            const body = await resp.json();
            if (body.status) {
                if (body.msg === 'new-record')
                    localStorage.setItem('rvp-token', body.resp.token);
                dispatch(shopRecentlyViewProducts(item));
            }
        } catch (err) {}
    }
};

// guardar productos vistos recientemente
const shopRecentlyViewProducts = (payload) => ({
    type: types.shopProductViewed,
    payload
});

// resetear cupón y eliminar método de envío seleccionado
export const checkoutLoaded = () => ({
    type: types.shopCheckoutLoaded
});

// mostrar mensajes si cupón es inválido
const printCartMessages = (cart_msg) => {
    if (cart_msg && cart_msg.length > 0) {
        let txt = '';
        for (const e of cart_msg) {
            txt += `<li>${e}</li>`;
        };
        htmlMsg(
            'info', 'Mensajes de su carrito',
            `<div style="text-align: left; padding-left: 0pt;">
                <ul style="margin-top: 1em;">${txt}</ul>
            </div>`
        );
    }
};